
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({
	name: 'QuantityTicker',
	components: {},
	emits: ['update:modelValue'],
})
export default class QuantityTicker extends Vue {
	@Prop() modelValue!: number;
	@Prop({ default: null }) min!: number | null;
	@Prop({ default: null }) max!: number | null;
	@Prop({ default: false }) isRequired!: boolean;
	@Prop({ default: 1 }) stepSize!: number;

	get value(): number {
		return this.modelValue;
	}

	set value(v: number) {
		this.update(Number(v));
	}

	increase(): void {
		var value = this.modelValue + this.stepSize;
		if (this.min && value < this.min) value = this.min; // start at minimum when incrementing
		this.update(value);
	}

	decrease(): void {
		var value = this.modelValue - this.stepSize;
		console.log(value);
		if (this.min && value < this.min) value = 0; // jump to 0 when decreasing if under min booking required
		this.update(value);
	}

	update(v: number): void {
		if (typeof v !== 'number') return;
		if ((this.min != null && v < this.min && this.isRequired) || (this.max != null && v > this.max)) return;
		this.$emit('update:modelValue', v);
	}
}
